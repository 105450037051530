import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import { toPng, toSvg } from 'html-to-image';
import Button from 'react-bootstrap/esm/Button';

const ProductCard = ({ image, image2, containers, bookmarks, chosenLogoColor, chosenRibbonColor, logoText, logo, description }) => {

  const [inside, setInside] = useState(false);

  return (
    <div style={{
      display: "flex", alignItems: "center", flexDirection: "column", width: "18vw", borderStyle: "outset", borderRadius: "2vh",
      margin: "1vh", padding: "2vh 0", borderWidth: "thick", fontFamily: "Cinzel"
    }}>
      {
        (containers === '1' && <div style={{ fontSize: "3vh" }}>Starter</div>) ||
        (containers === '2' && <div style={{ fontSize: "3vh" }}>Mid-Size</div>) ||
        (containers === '4' && <div style={{ fontSize: "3vh" }}>Large</div>)
      }
      <img src={inside ? image2 : image} style={{ width: "15vw" }}
        onMouseEnter={e => {
          setInside(true);
        }}
        onMouseLeave={e => {
          setInside(false);
        }} />
      <div style={{ marginTop: "1vh", textAlign: "center" }}>{description}</div>
      <div style={{ textAlign: "center" }}>Gold/Silver Logo Foil</div>
      {/* <div style={{ textAlign: "center" }}>Custom Bookmark Color</div> */}
      <div style={{ textAlign: "center" }}><b>{containers}</b> Container{containers === '1' ? "" : "s"}</div>
      <div style={{ textAlign: "center" }}><b>{bookmarks}</b> Bookmarks Total</div>
      <div style={{ borderBottomStyle: "solid", borderColor: "black", borderBottomWidth: "1px", width: "10vw", margin: "1vh 0" }}></div>
      {
        (containers === '1' && <div style={{ fontSize: "2.5vh" }}>
          {/* <span className="strikediag">$450 </span> */}
          $300</div>) ||
        (containers === '2' && <div style={{ fontSize: "2.5vh" }}>
          {/* <span className="strikediag">$800 </span> */}
          $550</div>) ||
        (containers === '4' && <div style={{ fontSize: "2.5vh" }}>
          {/* <span className="strikediag">$1,500 </span> */}
          $1,000</div>)
      }
      <Button variant="outline-primary" disabled={true} onClick={e => {
        var node = document.getElementById("DigitalBookmark");
        toSvg(node)
          .then(function (dataUrl) {
            // var img = new Image();
            // img.src = dataUrl;
            // var link = document.createElement('a');
            // link.download = 'digitalbookmark.svg';
            // link.href = dataUrl;
            // link.click();


            axios.post("/api/stripe/link", {
              amount: containers,
              logoColor: chosenLogoColor,
              ribbonColor: chosenRibbonColor,
              logoText,
              logo,
              digitalBookmark: dataUrl
            })
              .then((res) => {
                console.log("Res:", res);
                window.open(res.data.paymentLink, '_self');
              })
              .catch(err => {

              })
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });


      }}>Ordering Available Soon</Button>
    </div>
  )
}


export default ProductCard;