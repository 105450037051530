import Curvy from '../media/Curvy.jpg';


const CurvyProductImage = ({ color, text, fill, fullSize }) => {

  return (
    <div id="CurvyProductImage" style={{ position: "relative", height: "60vh", width: "80vh", height: fullSize ? "100%" : "60vh", width: fullSize ? "100%" : "80vh", margin: "auto" }}>
      <div height={"100%"} width={"100%"}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink="http://www.w3.org/1999/xlink" id="product-svg" viewBox="0 0 4032 3024" style={{ position: "relative", zIndex: 2, mixBlendMode: "multiply" }}>
          <g viewBox="0 0 4032 3024">
            <path stroke="#000000" strokeWidth="0.0" d="M1181.47+984.831L1188.47+1094.71L1221.01+1086.99L1262.21+1077.18L1289.69+1069.77L1306.38+1066.54L1413.79+1045.09L1547.14+1015.89L1648.03+989.859L1771.05+961.944L1853.42+944.107L2004.07+920.215L2086.84+910.522L2184.25+907.199L2276.92+907.199L2410.11+911.36L2487.7+921.059L2577.96+937.744L2631.31+950.841L2697.33+971.067L2756.12+990.477L2807.54+1006.78L2889.34+1047.51L2944.96+1078.65L3016.58+1126.62L3071.52+1174.44L3104.71+1215.49L3125.28+1252.18L3145.68+1301.51L3146.67+1319.72L3119.81+1347.9L3095.82+1369.15L3042.63+1405.81L2976.85+1444.15L2901.2+1478.07L2800.48+1510.08L2708.54+1539.17L2581.78+1569.38L2434.36+1602.56L2325.2+1624.67L2187.72+1655.96L2023.14+1692.78L1838.19+1746.41L1700.76+1790.63L1579.67+1833.5L1524.85+1854.83L1531.53+1963.46L1536.19+1971.19L1593.48+1946.45L1690.5+1910.55L1781.22+1880.55L1968.6+1819.5L2120.68+1779.47L2264.73+1743.8L2398.22+1713.5L2477.83+1693.81L2608.27+1661.57L2705.03+1633.22L2838.33+1586L2972.77+1528.34L3047.54+1484.3L3115.97+1432.04L3148.73+1379.59L3165.98+1334.95L3173.19+1305.91L3182.69+1268.49L3186.3+1235.06L3185.16+1205.62L3170.23+1171.4L3148.04+1140.92L3096.29+1084.97L3006.85+1015.7L2906.63+960.338L2788.46+912.119L2656.56+867.129L2567.16+847.151L2431.73+828.389L2241.97+824.152L1987.2+840.257L1750.18+882.659L1554.03+917.171L1360.05+950.152L1211.42+979.291L1181.47+984.831Z" fill={color} strokeLinejoin="round" />
            <path id="TextPath2" fill="transparent" d="M1580.58+1935.1L1983.31+1800.12L2376.61+1703.69L2668.61+1631.69L2971.71+1530.36L3111.39+1432.06" transform="matrix(1 -0 -0 1 0 0)" />
            <text><textPath className="noselect" xlinkHref="#TextPath2" style={{ baselineShift: "5px", fontSize: "78px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
          </g>
        </svg>
      </div>
      <img id="background-image" src={Curvy} alt="" style={{ position: "absolute", top: 0, width: "100%", height: "auto" }} />
    </div>
  );
}

export default CurvyProductImage;