import { useEffect } from "react";
import data from "./data.gif";

const FlipScreen = ({ width, height }) => {

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <img src={data} />
      <div style={{ fontSize: "2vh", textAlign: "center" }}>Please flip screen, or for the best experience--<br /> switch to desktop!</div>
    </div>
  );
}

export default FlipScreen;