import SingleProductImage from "./components/Images/SingleProductImage";
import CurvyProductImage from "./components/Images/CurvyProductImage";
import MainViewProductImage from "./components/Images/MainViewProductImage";
import SideViewProductImage from "./components/Images/SideViewProductImage";

import SingleProductImageLogo from "./components/ImagesLogo/SingleProductImageLogo";
import CurvyProductImageLogo from "./components/ImagesLogo/CurvyProductImageLogo";
import MainViewProductImageLogo from "./components/ImagesLogo/MainViewProductImageLogo";
import SideViewProductImageLogo from "./components/ImagesLogo/SideViewProductImageLogo";
import { toPng, toSvg } from 'html-to-image';
import Button from "react-bootstrap/esm/Button";
import axios from "axios";



const Full = () => {
  const currentImage = localStorage.getItem("currentImage");
  const chosenLogoColor = localStorage.getItem("chosenLogoColor");
  const chosenRibbonColor = JSON.parse(localStorage.getItem("chosenRibbonColor"));
  const logoText = localStorage.getItem("logoText");
  const logo = localStorage.getItem("logo");
  const logoWidth = localStorage.getItem("logoWidth");
  const logoHeight = localStorage.getItem("logoHeight");
  const digitalBookmark = localStorage.getItem("digitalBookmark");


  axios.post("/api/gen/generate", {
    productPreview: currentImage,
    logoColor: chosenLogoColor,
    ribbonColor: chosenRibbonColor,
    logoText,
    logo,
    digitalBookmark
  })
    .then((res) => {
      console.log("Res:", res);
    })
    .catch(err => {

    })

  const downloadSVG = () => {
    var node = document.getElementById(currentImage);
    toSvg(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        var link = document.createElement('a');
        link.download = 'bookmark.svg';
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  const downloadPNG = () => {
    var node = document.getElementById(currentImage);
    toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        var link = document.createElement('a');
        link.download = 'bookmark.png';
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outline-primary" onClick={e => downloadPNG(e)} style={{ margin: "2vh", }}>Download PNG</Button>
        <Button variant="outline-primary" onClick={e => downloadSVG(e)} style={{ margin: "2vh", }}>Download SVG</Button>
      </div>
      <div id="fullSizeImage">
        {currentImage === 'SingleProductImage' && <SingleProductImage color={chosenRibbonColor.hex} text={logoText} fill={chosenLogoColor} fullSize={true} />}
        {currentImage === 'CurvyProductImage' && <CurvyProductImage color={chosenRibbonColor.hex} text={logoText} fill={chosenLogoColor} fullSize={true} />}
        {currentImage === 'MainViewProductImage' && <MainViewProductImage color={chosenRibbonColor.hex} text={logoText} fill={chosenLogoColor} fullSize={true} />}
        {currentImage === 'SideViewProductImage' && <SideViewProductImage color={chosenRibbonColor.hex} text={logoText} fill={chosenLogoColor} fullSize={true} />}

        {currentImage === 'SingleProductImageLogo' && <SingleProductImageLogo color={chosenRibbonColor.hex} logo={logo} fill={chosenLogoColor} logoWidth={logoWidth} logoHeight={logoHeight} fullSize={true} />}
        {currentImage === 'CurvyProductImageLogo' && <CurvyProductImageLogo color={chosenRibbonColor.hex} logo={logo} fill={chosenLogoColor} logoWidth={logoWidth} logoHeight={logoHeight} fullSize={true} />}
        {currentImage === 'MainViewProductImageLogo' && <MainViewProductImageLogo color={chosenRibbonColor.hex} logo={logo} fill={chosenLogoColor} logoWidth={logoWidth} logoHeight={logoHeight} fullSize={true} />}
        {currentImage === 'SideViewProductImageLogo' && <SideViewProductImageLogo color={chosenRibbonColor.hex} logo={logo} fill={chosenLogoColor} logoWidth={logoWidth} logoHeight={logoHeight} fullSize={true} />}

      </div>
      {/* <canvas id="myCanvas"></canvas> */}
    </div>
  );
}

export default Full;