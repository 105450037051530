import SideView from '../media/SideView.jpg';


const SideViewProductImage = ({ color, text, fill, fullSize }) => {

  return (
    <div id="SideViewProductImage" style={{ position: "relative", height: fullSize ? "100%" : "60vh", width: fullSize ? "100%" : "80vh", margin: "auto" }}>
      <div height={"100%"} width={"100%"}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink="http://www.w3.org/1999/xlink" id="product-svg" viewBox="0 0 4032 3024" style={{ position: "relative", zIndex: 2, mixBlendMode: "multiply" }}>
          <g viewBox="0 0 4032 3024">
            <path stroke="#000000" strokeWidth="0.0" d="M3625.9+2315.78L3646.03+2312.48L3649.69+2329.16L3652.98+2355.38L3655.65+2384.78L3654.38+2418.69L3650.84+2447.87L3646.45+2483.8L3645.21+2514.38L3645.93+2546.77L3650.77+2571.62L3656.48+2601.24L3665.3+2632.62L3676.19+2663.91L3685.36+2685.52L3695.91+2707.12L3709.6+2736.11L3694.55+2754.25L3685.34+2743.26L3666.08+2705.49L3645.6+2659.21L3627.65+2603.9L3619.8+2551.94L3618.93+2501.36L3622.68+2453.66L3626.99+2412.9L3628.31+2392.74L3627.01+2372.59L3626.38+2345.01L3625.9+2315.78Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M3422.91+2264.21L3424.33+2276.09L3429.47+2286.06L3435.46+2301.44L3438.13+2323.29L3440.63+2341.4L3442.44+2373.34L3445.41+2417.64L3446.08+2452.31L3446.65+2489.98L3444.75+2527.3L3441.84+2553.63L3436.32+2585.9L3425.64+2638.84L3415.36+2679.29L3411.38+2698.72L3410.21+2703.14L3405.35+2700.01L3404.24+2674.26L3410.84+2652.11L3417.9+2630.18L3424.94+2605.53L3430.77+2576.47L3434.49+2545.06L3436.07+2516.82L3435.53+2483.87L3434.91+2460.68L3434.7+2440.7L3434.07+2423.15L3433.32+2408.61L3433.06+2396.58L3431.46+2383.05L3430.01+2371.15L3428.09+2357.6L3423.12+2338.62L3417.59+2323.12L3413+2309.29L3412.25+2299.51L3418.15+2292.41L3422.91+2264.21Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M3232.12+2297.32L3239.59+2296.29L3241.16+2291.7L3245.82+2291.08L3249.55+2292.33L3245.31+2307.77L3241.02+2323.07L3235.88+2347.79L3229.29+2379.95L3223.63+2409.91L3217.26+2439.41L3209.47+2470.58L3198.05+2509.18L3187.15+2542.17L3179.81+2563.69L3171.59+2588.44L3165.59+2606.2L3158.32+2627.41L3151.32+2644.9L3136.91+2675.28L3124.94+2653.47L3139.97+2622.55L3150.19+2597.02L3163.25+2563.2L3177.63+2521.38L3188.71+2486.75L3195.55+2462.04L3201.76+2437.88L3206.3+2419.49L3210.86+2397.83L3214.24+2374.84L3218.01+2353.6L3223.05+2330.39L3227.57+2312.4L3232.12+2297.32Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M3068.17+2286.69L3074.09+2286.65L3075.98+2311.04L3077.19+2330.32L3079.25+2354.89L3079.64+2385.44L3079.34+2422.36L3078.35+2442.07L3075.87+2458.87L3073.38+2472.87L3070.07+2486.37L3066.28+2499.34L3061.86+2515.44L3055.33+2537.26L3049.1+2557.84L3043.86+2573.62L3038.96+2589.44L3033.95+2603.02L3029.8+2613.63L3026.71+2621.81L3023.23+2623.94L3017.5+2623.64L3016.4+2619.5L3019.44+2614.61L3019.77+2607.24L3023.95+2599.27L3029.17+2588.8L3034.01+2574.62L3039.95+2558.2L3044.34+2543.41L3049.37+2527.04L3054.04+2511.23L3058.55+2494.67L3062.38+2478.42L3065.93+2460.53L3069.21+2440.25L3071.37+2423.06L3072.87+2406.65L3073.77+2389.29L3073.98+2372.91L3073.7+2358.36L3072.71+2343.87L3072.07+2330.01L3070.78+2319.17L3069.87+2306.18L3068.71+2294.87L3068.17+2286.69Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M2969.8+2273.65L2981.14+2273.46L2981.3+2315.8L2980.61+2353.52L2980.59+2375.05L2979.26+2407.69L2976.95+2444.8L2974.21+2478.3L2968.42+2516.56L2961.04+2548.41L2946.94+2599.32L2937.91+2628.7L2934.81+2627.11L2930.25+2618.4L2930.34+2608.01L2941+2568.53L2949.44+2537.45L2955.17+2513.38L2959.33+2486.38L2962.35+2463.07L2964.62+2442.26L2965.73+2421.32L2966.73+2403.72L2967.32+2388.35L2967.73+2373.97L2967.97+2359.67L2968.07+2345.38L2968.22+2327.32L2968.65+2309.47L2968.89+2293.27L2969.8+2273.65Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M2804.22+2252.23L2813.7+2253.04L2822.4+2248.97L2827.96+2273.95L2834.13+2308.45L2838.19+2334.24L2838.95+2373.37L2837.03+2417.94L2833.82+2453.17L2830.86+2481.96L2823.76+2532.05L2816.26+2577.2L2805.67+2628.71L2783.64+2639.98L2780.28+2636.11L2783.64+2616.77L2792.52+2567.59L2800.69+2520.47L2806.72+2468.77L2811.54+2411.97L2812.41+2364.97L2809.15+2330.4L2804.99+2306.15L2801.65+2280.72L2801.01+2263.83L2802.85+2252.17L2804.22+2252.23Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M2624.38+2238.14L2632.93+2224.83L2637.31+2255.24L2640.05+2290.32L2642.45+2333.09L2642.46+2373.56L2636.88+2414.99L2630.4+2445.3L2626.15+2465.52L2620.46+2487.68L2615.51+2506.61L2613.04+2517.77L2609.37+2526.97L2608.09+2532.91L2604.25+2544.38L2601.04+2554.33L2599.97+2565.08L2593.26+2576.18L2590.76+2583.42L2586.55+2579.48L2588.83+2565.17L2591.02+2555.78L2595.55+2539.61L2599.27+2523.27L2604.21+2501.55L2608.18+2484.26L2612.45+2461.15L2615.11+2443.86L2618.84+2419.78L2622.16+2395.13L2623.81+2373.67L2625.06+2338.75L2623.95+2312.7L2622.65+2293.2L2621.52+2269.49L2621.69+2239.35L2624.38+2238.14Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M2512.04+2226.19L2522.47+2212.63L2520.32+2242.88L2519.19+2268.15L2517.54+2303.6L2516.75+2328.38L2516.54+2359.46L2517.49+2397.19L2518.54+2424.9L2519.13+2443.79L2521.73+2469.94L2522.7+2491.23L2524.51+2510.94L2526.48+2528.32L2527.98+2545.05L2527.41+2549.07L2516.07+2560.55L2512.27+2558.72L2511.01+2550.37L2508.89+2534.77L2506.81+2513.67L2504.79+2493.73L2503.79+2471.83L2502.23+2445.61L2501.09+2418.25L2499.75+2391.79L2499.02+2371.53L2498.45+2350.67L2498.39+2326.7L2498.27+2307.08L2499.27+2287.86L2501.09+2270.97L2504.64+2253.4L2508.79+2235.98L2512.04+2226.19Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M2402.87+2219.33C2403.2+2219.29+2411.33+2218.59+2411.33+2218.59L2408.3+2245.59L2405.18+2272.05L2401.17+2299.89L2398.47+2328.28L2397.09+2347L2396.26+2368.48L2395.83+2384.07L2396.92+2407.51L2398.92+2435.15L2401.24+2456.25L2404.26+2486.37L2407.11+2515.86L2408.81+2533.68L2410.78+2549.45L2404.25+2562.5L2401.08+2560.7L2398.23+2547.08L2395.57+2530.21L2391.64+2500.85L2388.88+2471.85L2386.24+2444.65L2384.73+2420.08L2383.73+2386.96L2384.32+2362.84L2385.82+2337.97L2387.62+2315.63L2392.03+2282.16L2396.53+2248.29L2400.27+2222.28C2400.27+2222.28+2402.54+2219.38+2402.87+2219.33Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M2192.85+2206.62L2207.97+2206.43L2207.83+2247.1L2206.66+2285.56L2205.56+2321.72L2202.93+2361.8L2197.73+2401.58L2190.36+2438.73L2186.03+2458.1L2180.01+2476.23L2173.05+2497.44L2167.2+2514.36L2163.02+2524.52L2159.99+2535L2157.11+2536.96L2154.6+2532.85L2155.04+2528.41L2157.71+2522.31L2158.22+2515.44L2170.34+2487.06L2174.98+2472.6L2178.99+2456.09L2184.3+2427.4L2188.13+2400L2191.74+2369.2L2191.64+2336.6L2191.05+2303.09L2190.61+2271.61L2190.71+2222.2L2192.85+2206.62Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M1995.59+2178.08L2007.24+2178.16L2014.16+2177.36L2020.33+2176L2022.05+2184.42L2025.94+2196.54L2030.4+2212.72L2037.29+2239.76L2044.21+2267.22L2051.67+2305.55L2057.12+2341.64L2062.52+2375.48L2064.21+2393.2L2064.49+2415.34L2063.86+2439.14L2061.01+2471.89L2059.25+2496.33L2058.14+2499.62C2058.14+2499.62+2036.31+2516.5+2036.4+2516.37C2036.49+2516.24+2032+2512.79+2032+2512.79L2032.76+2484.88L2033.37+2458.25L2032.91+2432.6L2031.59+2406.13L2028.34+2377.18L2022.71+2342.82L2017.6+2310.71L2010.52+2275.27L2003.52+2247.48L1998.11+2225.09L1995.37+2208.6L1994.92+2193.06L1995.59+2178.08Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M1863+2176.18L1870.65+2172.6L1882.88+2172.45L1880.04+2212.03L1878.4+2250.92L1877.92+2287.26L1878.51+2315.77L1879.37+2343.76L1879.61+2358.33L1880.22+2366.32L1880.26+2377.3L1880.3+2394.77L1879.76+2407.58L1878.99+2422.42L1877.02+2440.09L1874.52+2458.47L1870.4+2478.5L1866.58+2496.44L1857.98+2505.2L1851.58+2509.2L1847.66+2507.1L1849.52+2494.69L1852.38+2476.41L1856.08+2453.76L1858.88+2428.04L1858.68+2408.28L1857.54+2377.95L1856.25+2347.71L1855.17+2322.35L1854.52+2299.56L1854.1+2278.54L1854.26+2254.61L1856.02+2227.68L1857.16+2201.19L1859.69+2180.76L1863+2176.18Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M1672.41+2158.13L1682.13+2156.85L1694.15+2152.3L1691.04+2174.65C1691.04+2174.65+1688.41+2193.62+1688.64+2193.62C1688.88+2193.62+1687.65+2218.55+1687.65+2218.55L1687.06+2242.74L1687.26+2260.96L1689.39+2279.55L1690.83+2297.19L1693.89+2321.07L1696.48+2341.54L1699.45+2363.12L1700.92+2378.9L1702.03+2395.39L1703.53+2419.6L1705.23+2450.18L1694.93+2456.13L1684.19+2457.18L1680.84+2424.64L1677.44+2384.06L1672.37+2352.35L1667.6+2319.1L1664.17+2292.49L1661.08+2258.67L1662.01+2222.9L1665.06+2193.2L1668.64+2171.78L1672.41+2158.13Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M1555.15+2150.55L1566.34+2147.38L1575.46+2145.12L1574.52+2181.59L1571.8+2228.5L1569.3+2264.62L1566.38+2305.16L1565.08+2336.42L1564.08+2362.03L1563.37+2392.98L1561.05+2418.21L1550.59+2425.02L1539.73+2432.66L1535.5+2432.23L1534.55+2428.11L1536.71+2410.12L1539.69+2388.3L1543.5+2359.24L1545.11+2338.22L1546.59+2308.38L1548.12+2274.16L1550.03+2236.41L1551.1+2215.97L1552.4+2192.98L1553.87+2167.04L1555.15+2150.55Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M1436.02+2144.9L1451.7+2143.66L1459.06+2139.28L1466.21+2132.86L1470.24+2132.38L1470.58+2137.8L1467.6+2140.02L1469.89+2143.14L1471.38+2159.47L1471.92+2182.78L1473.46+2210.16L1474.85+2247.9L1475.16+2279.16L1475.09+2305.89L1474.61+2331.05L1474.5+2354.67L1473.32+2378.37L1472.32+2395.81L1471.41+2413.49L1471.08+2431.53L1467.66+2434.91L1439.21+2440.4L1436.12+2438.43L1435.48+2430.5L1436.38+2404.39L1436.99+2364.06L1437.08+2321.94L1436.7+2271.9L1435.28+2228.13L1434.29+2185.75L1434.71+2159.13L1436.02+2144.9Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M1349.96+2137.6L1358.4+2136.97L1371.58+2126.21L1373.42+2133.33L1376.09+2136.34L1384.04+2141.43L1384.06+2157.8L1379.56+2186.38L1373.02+2217.64L1367.93+2251.99L1361.92+2290.21L1355.24+2345.86L1348.64+2394.57L1335.07+2401.89L1311.27+2402.33L1313.23+2377.58L1316.19+2352.9L1320.32+2318.65L1324.39+2282.48L1328.09+2254.22L1332.07+2228.52L1336.81+2199.13L1342.4+2175.51L1346.72+2154.32L1349.96+2137.6Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M1258.43+2130.74L1282.81+2125.19L1286.23+2130.75L1284.36+2154.6L1284.48+2181.13L1283.85+2210.26L1284.65+2238.73L1285.91+2262.71L1287.4+2282.8L1289.35+2304.02L1290.65+2322.98L1291.39+2339.37L1292.82+2357.18L1292.53+2373.26L1263.1+2387.39L1261.19+2386.06L1261.39+2375.67L1259.68+2358.38L1257.38+2333.33L1254.88+2310.74L1253.27+2273.66L1252.32+2247.61L1251.53+2220.72L1252.32+2181.18L1254.04+2156.7L1255.46+2142.11L1256.96+2135.62L1258.43+2130.74Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M1110.8+2107.01L1120.49+2102.17L1122.16+2106.09L1121.46+2114.45L1124.91+2122.2L1134.84+2127.87L1144.75+2129.03L1152.14+2162.54L1156.51+2197.04L1162.32+2243.78L1166.14+2301.95L1167.32+2339.65L1164.87+2394.14L1141.34+2406.74L1138.51+2403.91L1138.76+2386.17L1138.97+2356.2L1138.14+2325.59L1136.32+2295.53L1132.36+2265.59L1130.24+2238.26L1127.16+2211.29L1122.73+2183.66L1118.3+2159.83L1113.06+2138.47L1110.36+2120.37L1110.8+2107.01Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M979.369+2095.64L999.604+2094.32L999.401+2099.51L1004.18+2103.98L1009.06+2106.22L1013.72+2130.44L1017.85+2168.36L1022.59+2214.98L1025.07+2289.52L1026.31+2328.45L1027.94+2364.33L1022.51+2368.99L988.881+2375.97L984.722+2374.34L983.453+2350.73L982.135+2326.84L981.803+2301.9L981.833+2276.42L980.633+2253.27L979.205+2235.11L978.406+2217.66L976.765+2200.45L975.694+2185.89L974.027+2164.7L972.333+2144.17L971.369+2125.46L972.066+2111.06L974.563+2102.58L979.369+2095.64Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M842.457+2085.24L848.759+2082.48L855.749+2080.91L863.911+2077.4L863.536+2095.75L863.363+2111.11L862.937+2131.83L863.16+2152.95L862.799+2170.04L862.947+2188.78L862.191+2208.53L859.839+2226.48L858.275+2245.79L856.632+2265.01L854.158+2284.8L851.293+2307.52L848.053+2337.32L838.952+2341.77L827.475+2346.02L817.11+2348.55L814.632+2345.72L814.419+2340.25L815.492+2328.53L817.937+2310.11L821.439+2282.58L825.324+2260.11L827.37+2239.85L829.267+2218.46L830.707+2197.15L831.495+2178.89L832.819+2157.41L833.653+2133.19L835.191+2114.47L838.972+2099.08L842.457+2085.24Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M631.921+2071.68L647.691+2070.81L658.676+2061.35L666.457+2073.06L668.138+2095.28L671.038+2121.98L674.157+2149.17L679.577+2183.99L684.672+2222.61L688.899+2258.08L693.739+2299.24L695.723+2321.13L692.934+2325.15L653.457+2334.22L651.204+2328.48L649.741+2311.81L647.482+2289.7L644.781+2267.87L643.003+2249.36L640.956+2231.03L638.515+2213.88L636.892+2202.3L635.934+2191.6L634.753+2180.02L632.688+2167.48L630.983+2155.55L629.023+2136.69L627.756+2117.93L627.08+2100.86L628.263+2083.2L631.921+2071.68Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0.0" d="M487.949+2056.89L500.517+2054.33L516.899+2044.01L517.962+2059.58L517.962+2062.77L523.402+2066.15L526.232+2067.54L526.665+2087.18L530.481+2131.8L532.969+2154.55L534.302+2187.6L534.125+2211.51L535.616+2231.35L536.665+2257.78L537.849+2274.67L538.796+2292.12L539.379+2316.12L539.76+2335.71L535.626+2341.12L506.238+2352.13L500.513+2350.34L498.86+2343.67L498.923+2320.96L498.777+2301.51L498.28+2283.5L496.662+2265.77L495.516+2245.4L494.701+2227.76L493.228+2209.66L491.829+2194.44L491.029+2178.45L489.73+2163.74L488.27+2145.08L487.864+2127.72L485.472+2105.46L484.223+2090.89L484.015+2072.86L485.472+2062.94L487.949+2056.89Z" fill={color} strokeLinejoin="round" />

            <path id="TextPath_1" fill="transparent" d="M3706.74+2734.03L3662.39+2646.5L3642.08+2560.54L3644.74+2468.25L3651.28+2397.53L3644.25+2321.44" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_2" fill="transparent" d="M2803.18+2625.64L2823.93+2503.14L2834.4+2406.29L2834.47+2334.67L2819.32+2257.05" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_3" fill="transparent" d="M2598.26+2562.74L2619.28+2484.01L2636.33+2404.58L2639.65+2309.25L2631.9+2240.41" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_4" fill="transparent" d="M2525.89+2542.41L2518.92+2465L2513.61+2359.68L2518.32+2230.25" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_5" fill="transparent" d="M2408.51+2546.47L2396.81+2441.06L2395.37+2336.28L2407.69+2231.63" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_6" fill="transparent" d="M2055.78+2492.41L2061.26+2404.9L2042.01+2269.41L2018.41+2181.68" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_7" fill="transparent" d="M1865.5+2492.81L1876.06+2427.91L1877.01+2366.15L1874.77+2284.61L1878.17+2175.57" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_8" fill="transparent" d="M1702.07+2444.79L1693.35+2341.09L1683.89+2262.19L1689.87+2157.6" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_9" fill="transparent" d="M1557.81+2414.59L1571.65+2153.81" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_10" fill="transparent" d="M1468.34+2427.17L1472.37+2271.66L1466.46+2148.97" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_11" fill="transparent" d="M1345.64+2388.85L1379.95+2148.08" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_12" fill="transparent" d="M1290.41+2367.78L1283.23+2268.97L1282.34+2135.1" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_13" fill="transparent" d="M1162.48+2389.92L1164.33+2307.29L1154.1+2195.03L1141.31+2132.72" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_14" fill="transparent" d="M1022.21+2356.96L1019+2223.29L1005.72+2112.24" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_15" fill="transparent" d="M845.131+2329.39L859.339+2209.25L860.585+2086.11" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_16" fill="transparent" d="M691.163+2317.53L663.937+2076.72" transform="matrix(1 -0 -0 1 0 0)" />
            <path id="TextPath_17" fill="transparent" d="M537.4+2333.67L520.773+2073.11" transform="matrix(1 -0 -0 1 0 0)" />

            <text><textPath className="noselect" xlinkHref="#TextPath_1" style={{ fontSize: "25px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_2" style={{ fontSize: "25px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_3" style={{ fontSize: "22px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_4" style={{ fontSize: "21px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_5" style={{ fontSize: "20px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_6" style={{ fontSize: "27px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_7" style={{ fontSize: "23px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_8" style={{ fontSize: "24px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_9" style={{ fontSize: "22px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_10" style={{ baselineShift: "5px", fontSize: "29px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_11" style={{ baselineShift: "5px", fontSize: "29px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_12" style={{ baselineShift: "5px", fontSize: "25px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_13" style={{ baselineShift: "3px", fontSize: "25px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_14" style={{ baselineShift: "5px", fontSize: "30px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_15" style={{ baselineShift: "5px", fontSize: "27px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_16" style={{ baselineShift: "5px", fontSize: "29px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
            <text><textPath className="noselect" xlinkHref="#TextPath_17" style={{ baselineShift: "5px", fontSize: "25px", fontFamily: "cinzel", fill: fill }}>{text}</textPath></text>
          </g>
        </svg>
      </div>
      <img id="background-image" src={SideView} alt="" style={{ position: "absolute", top: 0, width: "100%", height: "auto", mixBlendMode: "multiply" }} />
    </div>
  );
}

export default SideViewProductImage;