import MainView from '../media/MainView.jpg';

import { GOLD, GOLD_FILTER, SILVER_FILTER } from '../../constants';

const MainViewProductImageLogo = ({ color, logo, fill, logoWidth, logoHeight, fullSize }) => {

  return (
    <div id="MainViewProductImageLogo" style={{ position: "relative", height: fullSize ? "100%" : "60vh", width: fullSize ? "100%" : "45vh", margin: "auto" }}>
      <div height={"100%"} width={"100%"} style={{ position: "relative" }}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 3024 4032" style={{ position: "relative", zIndex: 2, mixBlendMode: "multiply" }}>
          <g viewBox="0 0 3024 4032">
            <path stroke="#000000" strokeWidth="0" d="M2190.14+3060.75L2213.02+3064.93L2259.75+3065.18L2256.92+3134.49L2249.16+3224.49L2241.37+3295.14L2235.49+3406.65L2226.66+3542.55L2219.53+3656.28L2216.35+3858.04L2214.52+3864.7L2193.64+3862.8L2135.97+3856.87L2130.1+3850.8L2130.67+3774.13L2134+3691.84L2136.77+3613.78L2140.93+3535.33L2143.92+3477.9L2146.39+3424.24L2149.44+3369.72L2153.67+3323.44L2159.51+3257.31L2163.94+3200.37L2168.52+3130.98L2176.3+3086.39L2190.14+3060.75Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1992.47+2925.17L2005.78+2926.43L2006.42+2937.84L2011.91+2947.03L2023.11+2963.62L2036.98+2982.91L2045.52+2992.21L2042.38+3050.34L2036.35+3105.49L2033.34+3159.62L2032.11+3195.07L2028.89+3221.71L2028.01+3240.86L2027.14+3256.11L2024.44+3292.05L2020.99+3324.1L2020.23+3353.35L2018.21+3385.59L2016.7+3403.35L2011.66+3438.44L2009.03+3470.3L2006.03+3500.92L2002.02+3563.76L1999.02+3599.71L1998.21+3604.67L1988.44+3606.61L1932.52+3613.3L1928.88+3608.69L1929.51+3595.61L1932.32+3566.57L1934.39+3534.96L1937.04+3502.1L1939.3+3473.41L1940.77+3445.82L1941.38+3424.88L1947.87+3364.03L1953.11+3255.04L1960.41+3180.26L1961.52+3146.65L1963.76+3123.53C1963.76+3123.53+1965.16+3103.44+1965.33+3103.44C1965.5+3103.44+1967.17+3087.3+1967.17+3087.3L1968.74+3070.71L1970.6+3057.72L1970.47+3039.27L1973.01+3018.68L1974.93+3004.14L1975.96+2994.37L1977.97+2983.31L1981.01+2967.23C1981.01+2967.23+1982.77+2955.42+1982.87+2955.42C1982.96+2955.42+1984.74+2943.51+1984.74+2943.51L1989.04+2931.61L1992.47+2925.17Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1809.18+2840.94L1841.28+2844.98L1845.75+2857.85L1858+2866.78L1863.17+2874.58L1850.48+2941.98L1837.08+3013.59L1820.32+3100.44L1806.26+3175.95L1799.39+3209.08L1794.07+3235.56L1791.11+3246.06L1790.34+3255.86L1786.91+3267.37L1783.41+3275.52L1783+3283.11L1778.97+3290.6L1778.97+3296.69L1775.46+3304.65L1775.04+3311.87L1771.5+3318.68L1770.88+3324.99L1767+3333.41L1765.16+3339.41L1763.39+3343.81L1762.1+3346.94L1761.55+3351.41L1756.34+3363.39L1756.15+3367.32L1752.47+3372.14L1751.73+3377.96L1746.82+3386.76L1746.62+3388.05L1691.44+3388.06L1687+3386.29L1691.36+3371.72L1700.02+3344.97L1715.34+3297.45L1727.9+3249L1737.35+3201.93L1745.28+3163.92L1753.1+3131.93L1757.65+3109.64L1761.39+3095.1L1764.05+3075.28L1768.13+3057.38L1771.19+3041.79L1776.07+3012.94L1782.18+2977.46L1788.29+2947.63L1792.37+2928.64L1792.54+2916L1798.64+2887.19L1803.65+2862.02L1809.18+2840.94Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1698.47+2798.92L1718.35+2809.03L1751.62+2828.91L1749.85+2858.02L1746.52+2886.67L1737.12+2962.16L1729.45+3014.15L1723.11+3054.06L1714.53+3096.34L1707.18+3131.08L1697.26+3173.75L1678.06+3235.6L1676.65+3239.73L1657.33+3250.74L1624.37+3244.35L1630.27+3218.86L1642.24+3178.59L1656.55+3125.56L1664.97+3079.06L1671.31+3029.34L1677.86+2974.9L1683.52+2927.07L1687.41+2891.87L1690.33+2864.55L1692.63+2840.73L1698.47+2798.92Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1641.77+2759.7L1651.28+2770.38L1665.48+2781.32L1687.04+2793.24C1687.04+2793.24+1688.51+2794.43+1688.72+2794.43C1688.93+2794.43+1686.9+2803.31+1687.09+2803.29C1687.28+2803.28+1684.86+2835.16+1684.86+2835.16L1683.21+2854.76L1681.41+2875.76L1679.38+2897.83L1678.16+2910.96L1676.37+2925.55L1675.25+2927.6L1674.56+2940.83L1673.46+2944.21L1673.26+2957.87L1671.3+2963.47L1671.18+2973.22L1670.04+2980.26L1668.62+2988.58L1667.27+2994.45L1667.27+3002.45L1665.44+3010.5L1664.04+3020.22L1661.38+3030.59L1659.24+3046.6L1655.62+3065.33L1652.37+3078.79L1647.37+3097.18L1643.46+3116.8L1639.55+3131.43L1636.57+3138.08L1636.57+3146.94L1633.82+3152.21L1633.9+3159.62L1629.93+3168.64L1629.16+3175.28L1625.88+3188.42L1623.55+3190.46L1617.05+3190.86L1597.12+3191.86L1575.38+3194.5L1572.81+3192.2L1571.74+3186.65L1582.39+3147.43L1591.17+3116.53L1600.83+3070.33L1613.93+3010.62C1613.93+3010.62+1619.58+2971.57+1619.8+2971.57C1620.02+2971.57+1625.79+2918.76+1625.79+2918.76L1628.59+2890.78L1632.33+2855.56L1635.12+2831.35L1636.15+2810.19L1638.06+2790.01L1640.21+2773.5L1641.77+2759.7Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1539.62+2672.86L1553.3+2682.72L1565.25+2692.84L1577.57+2698.04L1588.52+2704.61L1595.54+2706.98L1591.61+2735.64L1588.26+2767.66L1581.29+2825.02L1574.09+2898.75L1566.85+2960.58L1565.58+2971.09L1564.92+2987.69L1561.37+3004.54L1563.31+3018.09L1561.19+3034.39L1559.93+3048.85L1558.33+3056.98L1557.9+3066.55L1556.96+3075.46L1556.02+3091.35L1555.16+3107.71L1553.06+3124.92L1551.9+3128.39L1552.6+3149.02L1551.37+3152.75L1548.32+3153.54L1500.99+3149.04L1497.56+3149.48L1495.39+3146.98L1497.93+3085.38L1502.23+3023.35L1508.14+2948.74L1515.31+2879.6L1521.67+2831.69L1525.48+2785.15L1532.95+2729.99L1537.02+2688.89L1539.62+2672.86Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1440.33+2579.72L1452.91+2589.32L1464.53+2595.18L1487.62+2606.49L1489.67+2619.95L1486.74+2654.19L1481.92+2691.88L1474.14+2765.36L1470.71+2792.73L1468.05+2812.78L1464.16+2822.43L1463.83+2837.04L1459.05+2857.67L1456.75+2882.85L1455.63+2894.64L1451.21+2901.59L1446.72+2928.4L1435.1+2988.45L1433.57+2993.09L1428.8+2994.63L1385.02+2994.63L1382.91+2977.86L1395.3+2921.14L1405.53+2862.26L1415.84+2789.08L1419.56+2753.53L1427+2702.49L1432.14+2639.12L1440.33+2579.72Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1383.37+2544.01L1396.72+2556.17L1404.79+2561.98L1419.86+2563.67L1429.61+2563.32L1428.28+2580.55L1422.69+2611.92L1417.63+2644.73L1411.33+2699.15L1404.28+2750.41L1399.6+2783.32L1396.19+2811.28L1393.86+2835.29L1388.72+2872.48L1383.78+2936.66L1336.06+2937.15L1328.74+2934.07L1330+2920.24L1332.71+2906.14L1336.47+2863.48L1340.32+2827.9L1351.8+2741.37L1357.97+2688.99L1369.52+2601.06L1380.14+2549.1L1383.37+2544.01Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1331.33+2506.25L1345.33+2516.39L1357.5+2521.75L1374.38+2523.39L1373.86+2539.29L1367.21+2573.3L1359.61+2623.45L1352.09+2676.76L1347.15+2726.05L1343.41+2774.23L1337.6+2828.02L1334.8+2861.09L1331.57+2896.53L1330.3+2900.05L1287.49+2904.75L1284.28+2904.21L1283.63+2896.99L1284.7+2877.62L1287.12+2840.85L1290.65+2797.83L1294.06+2755.34L1297.62+2705.28L1305.44+2646.58L1311.72+2597.83L1320.75+2547.36L1329.51+2501.77L1331.33+2506.25Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1233.33+2450.76L1241.91+2460.46L1254.13+2470.25L1277.64+2479.21L1275.3+2532.19L1272.67+2540.38L1272.67+2569.44L1270.95+2612.6L1266.29+2655.87L1261.77+2691.59L1257.19+2724.63L1251.19+2755.19L1246.99+2772.7L1241.18+2795.96L1239.31+2798.18L1200.87+2805.33L1197.51+2804.42L1196.25+2797.59L1200.47+2781.71L1205.22+2757.53L1208.96+2735.53L1211.86+2713.32L1215.35+2689.1L1218.47+2660.79L1220.36+2638.75L1222.55+2614.27L1224.3+2588.1L1225.79+2560.49L1229.3+2520.19L1229.15+2489.1L1230.6+2458.88L1232.09+2449.59L1233.33+2450.76Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1165.09+2407.89L1179.36+2418.28L1192.96+2426.22L1204.19+2430.78L1201.99+2464.43L1198.2+2500.51L1197.22+2532.16L1196.38+2559.62L1196.42+2603.73L1195.78+2640.72L1194.13+2677.49L1194.63+2714.48L1194.64+2736.95L1195.88+2739.59L1195.49+2742.07L1193.44+2743.78L1188.7+2744.55L1151.95+2748.77L1146.96+2746.03L1145.66+2743.63L1144.91+2731.01L1144.51+2701.74L1145.11+2663.31L1147.16+2627.55L1148.44+2603.24L1152.01+2574.52L1153.87+2512.98L1156.22+2454.64L1165.09+2407.89Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M1112.26+2340.25L1122.99+2339.41L1121.45+2347.49L1123.89+2354.32L1130.09+2355.78L1129.4+2368.04L1129.4+2381L1131.07+2388.66L1134.83+2394.51L1141.8+2400.57L1146.12+2404.44L1146.02+2428.97L1142.45+2440.59L1139.82+2473.18L1139.26+2504.1L1137.02+2534.53L1135.29+2556.52L1134.25+2580.44L1132.59+2597.02L1131.94+2618.53L1129.7+2641.98L1127.3+2661.16L1121.31+2690.13L1118.82+2692.58L1115.87+2694.15L1077.15+2694.26L1074.46+2689.42L1077.72+2673.79L1081.68+2645.8L1085.27+2619.99L1087.94+2597.19L1089.2+2577.43L1092.12+2551.23L1096.39+2516.69L1098.8+2474.53L1100.34+2427.11L1105+2375.25L1112.26+2340.25Z" fill={color} strokeLinejoin="round" />

            <path stroke="#000000" strokeWidth="0" d="M842.467+2190.66L854.649+2198.05L866.659+2203.54L864.198+2230.67L862.378+2260.29L859.685+2301.34L858.471+2342.83L857.35+2400.16L855.59+2405.27L822.918+2400.41L821.159+2371.26L826.532+2314.26L830.794+2252.5L837.51+2202.5L842.467+2190.66Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M814.2+2129.36L823.512+2131.12L824.476+2138.59L824.074+2149.99L826.322+2161.31L831.621+2168.77L837.16+2172.79L836.082+2191.78L834.718+2212.96L830.325+2243.04L827.712+2274.68L825.046+2308.48L822.706+2335.22L821.085+2353.58L817.581+2357.53L798.271+2356.37L785.061+2355.26L782.339+2348.05L785.112+2327.38L789.259+2294.63L791.728+2257.37L798.306+2205.8L803.238+2157.73L811.29+2133.88L814.2+2129.36Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M765.402+2111.98L791.309+2124.04L790.928+2142.08L787.173+2175.23L785.056+2220.05L785.019+2254.39L784.797+2289.58L785.352+2318.78L781.91+2323.78L757.706+2321.67L757.595+2249.06L758.514+2194.65L761.1+2136.96L765.402+2111.98Z" fill={color} strokeLinejoin="round" />
            <path stroke="#000000" strokeWidth="0" d="M728.636+2098.99L745.764+2101.45L753.364+2112.69L758.502+2114.19L757.432+2135.81L755.696+2169.65L756.85+2203.19L756.412+2235.65L756.142+2267.7L757.167+2292.6L757.193+2313.62L756.765+2320.66L754.807+2323.11L729.54+2322.43L725.441+2317.36L725.747+2295.39C725.747+2295.39+725.628+2251.78+725.558+2251.71C725.488+2251.64+724.739+2206.04+724.739+2206.04L725.262+2162.86L725.597+2108.81L728.636+2098.99Z" fill={color} strokeLinejoin="round" />
          </g>
        </svg>
      </div>

      <svg style={{ position: "absolute", top: "54.4%", left: "21%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-91deg)", zIndex: "3" }}
        width={"7%"} height={"1%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "54.5%", left: "22%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-90deg)", zIndex: "3" }}
        width={"7%"} height={"1%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "55.3%", left: "23.3%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-86deg)", zIndex: "3" }}
        width={"7%"} height={"1%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "56.5%", left: "24.5%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-87deg)", zIndex: "3" }}
        width={"6.8%"} height={"1%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>
      {/*  */}
      <svg style={{ position: "absolute", top: "0", left: "0" }} viewBox="0 0 3024 4032">
        <path stroke="#000000" strokeWidth="0" d="M878.123+2201.79L896.196+2205.01L913.601+2223.33L915.651+2227.21L915.158+2261.14L911.768+2315.68L910.263+2347.16L907.647+2384.92L904.128+2416.91L901.877+2452.01L901.374+2455.03L899.159+2457L879.933+2455.94L877.975+2384.43L876.167+2314.14L876.067+2261.75L878.123+2201.79Z" fill={color} strokeLinejoin="round" />
      </svg>

      <svg style={{ position: "absolute", top: "57.5%", left: "26.2%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-92deg)" }}
        width={"7.2%"} height={"1%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>
      {/*  */}
      <svg style={{ position: "absolute", top: "0", left: "0" }} viewBox="0 0 3024 4032">
        <path stroke="#000000" strokeWidth="0" d="M919.915+2224.76L932.318+2238.31L948.265+2245.08L945.511+2301.91L941.823+2356.23L935.245+2400.74L927.643+2435.59L924.684+2449.32L922.704+2457.5L901.449+2456.98L902.831+2434.12L908.392+2368.81L914.078+2280.38L915.721+2225.05L919.915+2224.76Z" fill={color} strokeLinejoin="round" />
      </svg>

      <svg style={{ position: "absolute", top: "57.5%", left: "27.2%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-90deg)" }}
        width={"7.2%"} height={"1%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>
      {/*  */}
      <svg style={{ position: "absolute", top: "0", left: "0" }} viewBox="0 0 3024 4032">
        <path stroke="#000000" strokeWidth="0.0" d="M948.398+2245.05L956.968+2257.25L967.288+2262.76L971.397+2263.56L968.455+2297.95L966.867+2319.06L965.497+2345.37L963.056+2374.43L962.573+2418.62L961.384+2459.74L958.028+2485.3L942.38+2484.35L922.403+2482.93L923.264+2456.3L933.943+2406.08L940.466+2362.14L945.557+2299.35L947.902+2252.48L948.398+2245.05Z" fill={color} strokeLinejoin="round" />
      </svg>

      <svg style={{ position: "absolute", top: "58.3%", left: "28%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-87deg)" }}
        width={"7.2%"} height={"1.1%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>
      {/*  */}

      <svg style={{ position: "absolute", top: "0", left: "0" }} viewBox="0 0 3024 4032">
        <path stroke="#000000" strokeWidth="0.0" d="M971.345+2263.53L988.1+2266.84L998.243+2266.5L1003.6+2275.39L1004.17+2288.5L1003.14+2307.19L1004.41+2309.11L1003.49+2331.98L1001.89+2376.15L1000.4+2419.07L997.12+2469.54L992.943+2517.12L990.216+2542.79L987.169+2543.94L962.393+2543.02L955.955+2536.59L958.772+2505.95L961.017+2472.92L961.916+2426.11L963.085+2377.57L967.164+2314.63L971.345+2263.53Z" fill={color} strokeLinejoin="round" />
      </svg>

      <svg style={{ position: "absolute", top: "58.9%", left: "28%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-88deg)" }}
        width={"9.2%"} height={"1.1%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>
      {/*  */}

      <svg style={{ position: "absolute", top: "0", left: "0" }} viewBox="0 0 3024 4032">
        <path stroke="#000000" strokeWidth="0" d="M1006.73+2312.17L1023.17+2322.25L1040.96+2329.75L1037.37+2370.41L1034.46+2405.82L1031.2+2446.9L1028.4+2490.98L1022.75+2547.65L1021.32+2552.58L991.127+2551.51L991.127+2543.26L999.027+2441.5L1003.06+2353.49L1004.06+2313.32L1003.72+2309.12L1006.73+2312.17Z" fill={color} strokeLinejoin="round" />
      </svg>

      <svg style={{ position: "absolute", top: "59.6%", left: "29.4%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-88deg)" }}
        width={"8.2%"} height={"1.2%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>
      {/*  */}

      <svg style={{ position: "absolute", top: "0", left: "0" }} viewBox="0 0 3024 4032">
        <path stroke="#000000" strokeWidth="0" d="M1040.52+2329.54L1077.36+2337.11L1081.71+2339.07L1076.72+2386.06L1073.17+2455.3L1067.42+2536.64L1064.07+2584.6L1060.36+2605.05L1051.85+2607.11L1017.94+2605.19L1021.79+2557.86L1028.25+2494L1031.88+2429.25L1037.71+2363.56L1040.52+2329.54Z" fill={color} strokeLinejoin="round" />
      </svg>

      <svg style={{ position: "absolute", top: "60.7%", left: "30.3%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-87deg)" }}
        width={"8.8%"} height={"1.2%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>
      {/*  */}

      <svg style={{ position: "absolute", top: "62.4%", left: "31.9%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-86deg)", zIndex: 3 }}
        width={"9.8%"} height={"1.2%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "63.5%", left: "33.7%", rotate: "0.3deg", transform: "rotateX(0deg) rotateZ(-88deg)", zIndex: 3 }}
        width={"10.4%"} height={"1.2%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "64.6%", left: "35.8%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(20deg) rotateZ(-86deg) skewX(10deg)", zIndex: 3 }}
        width={"10.4%"} height={"1.3%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "66.6%", left: "37.7%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(20deg) rotateZ(-84.5deg)", zIndex: 3 }}
        width={"12.1%"} height={"1.3%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "67.4%", left: "39.5%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(20deg) rotateZ(-84.5deg)", zIndex: 3 }}
        width={"12.1%"} height={"1.4%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "68.5%", left: "41.3%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(20deg) rotateZ(-83deg)", zIndex: 3 }}
        width={"12.4%"} height={"1.4%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "71.5%", left: "43.8%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(20deg) rotateZ(-85deg)", zIndex: 3 }}
        width={"14.4%"} height={"1.6%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "73.2%", left: "47.65%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(30deg) rotateZ(-80deg) skewX(10deg)", zIndex: 3 }}
        width={"12.9%"} height={"1.6%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "74.3%", left: "48.7%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(35deg) rotateZ(-79deg)", zIndex: 3 }}
        width={"14.4%"} height={"1.65%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "76.5%", left: "50.1%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(30deg) rotateZ(-77deg) skewX(10deg)", zIndex: 3 }}
        width={"17.4%"} height={"1.7%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "80.5%", left: "55.1%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(30deg) rotateZ(-86deg) skewX(10deg)", zIndex: 3 }}
        width={"21%"} height={"1.7%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <svg style={{ position: "absolute", top: "85.3%", left: "59.8%", rotate: "0.3deg", transform: "rotateX(0deg) rotateY(20deg) rotateZ(-87deg)", zIndex: 3 }}
        width={"25%"} height={"1.7%"} viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 5 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>

      <img id="background-image" src={MainView} alt="" style={{ position: "absolute", top: 0, width: "100%", height: "auto", mixBlendMode: "multiply" }} />

    </div>
  );
}

export default MainViewProductImageLogo;