import TopView from '../media/top.jpg';

import { GOLD, GOLD_FILTER, SILVER_FILTER } from '../../constants';

const SingleProductImageLogo = ({ color, logo, fill, logoWidth, logoHeight, fullSize }) => {

  return (
    <div id="SingleProductImageLogo" style={{ position: "relative", height: fullSize ? "100%" : "60vh", width: fullSize ? "100%" : "80vh", margin: "auto" }}>
      <div height={"100%"} width={"100%"} style={{ position: "relative" }}>
        <svg className="SingleProductImageLogoSVG" xmlns='http://www.w3.org/2000/svg' xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 4032 3024" style={{ position: "block", zIndex: 2, mixBlendMode: "multiply" }}>
          <g viewBox="0 0 4032 3024">
            <path stroke="#000000" strokeWidth="0.0" d="M196.93+1470.03L455.706+1468.55L812.308+1472.77L1421.35+1472.96L1672.96+1474.44L1940.23+1480.79L2262.6+1484.44L2568.72+1489.69L2808.14+1494.36L3089.79+1496.63L3379.22+1501.68L3640.72+1505.36L3854.78+1507.55L3864.5+1578.4L3864.53+1584.21L3836.7+1589.61L3670.46+1586.86L3458.88+1586.37L3227.8+1582.53L3005.02+1578.33L2832.65+1576.75L2679.22+1574.95L2524.72+1572.57L2415.22+1570.53L2278.77+1569.46L2177.25+1568.06L2047.17+1564.97L1914.68+1563.32L1775.56+1562.23L1647.8+1559.97L1538.69+1558.82L1412.25+1558.64L1281.5+1558.57L1157.35+1558.62L1033.32+1560.81L858.132+1559.66L709.512+1559.47L519.253+1561.31L320.313+1560.41L205.241+1565.45L186.113+1557.52L187.08+1513.31L186.528+1471.73L196.93+1470.03Z" fill={color} strokeLinejoin="round" />
          </g>
        </svg>

      </div>
      <svg className="SingleProductImageLogoSVG" style={{ position: "absolute", top: "48.7%", left: "5%", rotate: "0.3deg", zIndex: "3" }} width={"50%"} height={"3%"}
        viewBox={`0 0 ${logoWidth} ${logoHeight}`} preserveAspectRatio={`xMinYMid ${logoWidth / logoHeight < 25 ? "meet" : "slice"}`}>
        <image xlinkHref={logo} x={0} y={0} width={"100%"} height={"100%"} filter={fill === GOLD ? GOLD_FILTER : SILVER_FILTER} style={{ maxHeight: "3.5%" }}></image>
      </svg>
      <img id="background-image" src={TopView} alt="" style={{ position: "absolute", top: 0, width: "100%", height: "auto", mixBlendMode: "multiply" }} />
    </div>
  );
}

export default SingleProductImageLogo;