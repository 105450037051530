import { useLayoutEffect, useState } from 'react';
import SingleProductImage from './components/Images/SingleProductImage';
import SideViewProductImage from './components/Images/SideViewProductImage';
import MainViewProductImage from './components/Images/MainViewProductImage';
import BookstoreDescription from './components/Intro/BookstoreDescriptions';
import { YoutubeEmbed } from './components/Intro/YoutubeEmbed';
import RibbonColor from './components/CustomizeBookmark/RibbonColor/RibbonColor';
import Carousel from 'react-bootstrap/Carousel';
import CurvyProductImage from './components/Images/CurvyProductImage';
import React from 'react';
import ChooseLogo from './components/ChooseLogo/ChooseLogo';
import { GOLD, NEITHER, TEXT, IMAGE } from './constants';
import LogoColor from './components/ChooseLogo/LogoColor';
import Button from 'react-bootstrap/Button';
import SingleProductImageLogo from './components/ImagesLogo/SingleProductImageLogo';
import CurvyProductImageLogo from './components/ImagesLogo/CurvyProductImageLogo';
import MainViewProductImageLogo from './components/ImagesLogo/MainViewProductImageLogo';
import SideViewProductImageLogo from './components/ImagesLogo/SideViewProductImageLogo';
import NewTab from './components/media/NewTab.svg';
import ProductCard from './components/NearPayment/ProductCard';
import FinalBookmark from './components/NearPayment/FinalBookmarks';
import BookmarkDigital from './components/BookmarkDigital';

import TempContainer from './components/media/TempContainer.jpg';
import SingleStraight from './components/media/FinalPics/SingleStraight.jpeg';
import SingleSide from './components/media/FinalPics/SingleSide.jpeg';
import DoubleStraight from './components/media/FinalPics/DoubleStraight.jpeg';
import DoubleSide from './components/media/FinalPics/DoubleSide.jpeg';
import QuadStraight from './components/media/FinalPics/QuadStraight.jpeg';
import QuadSide from './components/media/FinalPics/QuadSide.jpeg';
import FlipScreen from './components/FlipScreen/FlipScreen';
import { toSvg } from 'html-to-image';
import CustomerExample from './components/NearPayment/CustomerExample';

import FrontBig from './pics/IMG_8912.jpg';
import FrontHigher from './pics/IMG_8913.jpg';
import SideFront from './pics/IMG_8914.jpg';
import BirdsEye from './pics/IMG_8917.jpg';
// import Curvy from './pics/IMG_8918.jpg';
// import Curvy from './pics/IMG_8928.jpg';
import Stacked from './pics/IMG_8931.jpg';




const BookmarksPage = () => {
  const [chosenRibbonColor, setChosenRibbonColor] = useState({ hex: "#9e9d95", name: "Off White", id: "000" });
  const [hoveringRibbonColor, setHoveringRibbonColor] = useState({ id: "-1" });

  const [chosenLogoColor, setChosenLogoColor] = useState(GOLD);
  const [hoveringLogoColor, setHoveringLogoColor] = useState(NEITHER);
  const [textOrImage, setTextOrImage] = useState(TEXT);
  const [hoveringTextOrImage, setHoveringTextOrImage] = useState(NEITHER);
  const [logo, setLogo] = useState();
  const [logoWidth, setLogoWidth] = useState();
  const [logoHeight, setLogoHeight] = useState();

  const [logoText, setLogoText] = useState('');

  const [showCustomizerButton, setShowCustomizerButton] = useState(true);

  window.addEventListener('scroll', (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    if (window.scrollY == 0)
      setShowCustomizerButton(true);
  }, true);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      window.addEventListener('orientationchange', updateSize);
      updateSize();
      return () => {
        window.removeEventListener('resize', updateSize);
        window.removeEventListener('orientationchange', updateSize);
      }
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  return (width < height ?
    <FlipScreen width={width} height={height} /> :
    <div style={{ backgroundColor: "white", height: "100vh", display: "flex", alignItems: "center", flexDirection: "column" }}>
      <div style={{
        fontSize: "5vh", fontFamily: "cinzel", color: "white",
        backgroundColor: "#202A44", width: "100%", textAlign: "center", zIndex: "5"
      }}>Ribbon Bookmarks</div>
      {/* {
        showCustomizerButton && <div style={{ position: "fixed", right: "1vw", bottom: "1vh" }}>
          <Button variant="danger" type="button" value="Customize..." onClick={e => {
            document.getElementById('customizer').scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'center'
            });
            setShowCustomizerButton(false);
          }}>
            <div>Go To Customizer</div>
          </Button>
        </div>
      } */}

      {/* <div style={{
        fontSize: "2vh", fontFamily: "cinzel", color: "white",
        backgroundColor: "orange", width: "100%", textAlign: "center", zIndex: "5"
      }}>Back to School Prices! Ends October 31st!</div> */}
      <div style={{ minHeight: "2vh" }}></div>
      {/* <div style={{ fontSize: "2.5vh", fontFamily: "cinzel", margin: "2vh 0" }}>“Custom bookmarks for every bookstore!”</div> */}
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* <YoutubeEmbed /> */}
        {/* <CustomerExample /> */}
      </div>
      {/* <BookstoreDescription /> */}
      {/* <div style={{ borderBottomStyle: "solid", borderColor: "black", borderBottomWidth: "1px", width: "50vw", margin: "2vh 0" }}></div>
      <div style={{ textAlign: "center", fontSize: "3vh", fontFamily: "cinzel" }}>Books are the past, present, and future.</div>
      <div style={{ borderBottomStyle: "solid", borderColor: "black", borderBottomWidth: "1px", width: "50vw", margin: "1vh 0" }}></div> */}
      {/* <div style={{ textAlign: "center", fontSize: "3vh", fontFamily: "cinzel" }}>Customize your bookmark below and see the results!</div> */}
      {/* <div style={{ textAlign: "center", fontSize: "3vh", fontFamily: "cinzel", width: "50vw" }}><i>"Once a student takes time to attach this ribbon bookmark, they have <u>CHANGED</u> and <u>BUILT UPON</u> what they're holding, granting them a sense of <u>OWNERSHIP</u> and transitively <u>RESPONSIBILITY</u>."</i></div> */}


      {/* <Button variant="outline-secondary" onClick={(e) => {
        var node = document.getElementById("DigitalBookmark");
        toSvg(node)
          .then(function (dataUrl) {
            let current = document.getElementsByClassName("active carousel-item")[0];
            localStorage.setItem("currentImage", current.children[0].id) //TODO
            localStorage.setItem("chosenLogoColor", chosenLogoColor);
            localStorage.setItem("chosenRibbonColor", JSON.stringify(chosenRibbonColor));
            localStorage.setItem("logoText", textOrImage === TEXT ? logoText : '');
            localStorage.setItem("logo", textOrImage === IMAGE ? (logo ? logo : '') : '');
            localStorage.setItem("logoWidth", logoWidth);
            localStorage.setItem("logoHeight", logoHeight);
            localStorage.setItem("digitalBookmark", dataUrl);
            window.open('/full', '_blank', 'noreferrer');
          })
          .catch(function () {

          })

      }} style={{ margin: "2vh 0" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>Larger View</div>
          <img src={NewTab} style={{ verticalAlign: "middle", height: "2vh", marginLeft: "0.5vw" }} />
        </div>
      </Button> */}



      <div id='customizer' style={{ display: "flex" }}>
        {/* <RibbonColor chosen={chosenRibbonColor} hovering={hoveringRibbonColor} setChosen={setChosenRibbonColor} setHovering={setHoveringRibbonColor} /> */}
        <div style={{ minWidth: "2vw" }}></div>
        <div style={{ width: "50vw", display: "flex" }}>
          {
            <Carousel slide={false} indicators={false} interval={null} >
              <Carousel.Item >
                <img id="background-image" src={FrontBig} alt="" style={{ width: "100%", height: "auto", margin: "auto" }} />
              </Carousel.Item>
              {/* <Carousel.Item>
                <img id="background-image" src={FrontHigher} alt="" style={{ width: "100%", height: "auto", margin: "auto" }} />
              </Carousel.Item> */}
              <Carousel.Item>
                <img id="background-image" src={SideFront} alt="" style={{ width: "100%", height: "auto", margin: "auto" }} />
              </Carousel.Item>
              <Carousel.Item>
                <img id="background-image" src={BirdsEye} alt="" style={{ width: "100%", height: "auto", margin: "auto" }} />
              </Carousel.Item>
              <Carousel.Item>
                <img id="background-image" src={Stacked} alt="" style={{ width: "100%", height: "auto", margin: "auto" }} />
              </Carousel.Item>
            </Carousel>
          }
        </div>
        <div style={{ minWidth: "2vw" }}></div>
        {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ textAlign: "center", fontFamily: "cinzel", fontSize: "2.5vh", margin: "1vh 0" }}>Logo & Color</div>
          <ChooseLogo chosenRibbonColor={chosenRibbonColor} logo={logo} setLogo={setLogo} logoText={logoText} setLogoText={setLogoText} chosenLogoColor={chosenLogoColor} textOrImage={textOrImage}
            setTextOrImage={setTextOrImage} hoveringTextOrImage={hoveringTextOrImage} setHoveringTextOrImage={setHoveringTextOrImage} setLogoWidth={setLogoWidth} setLogoHeight={setLogoHeight} />
          <LogoColor chosenLogoColor={chosenLogoColor} hoveringLogoColor={hoveringLogoColor} setChosenLogoColor={setChosenLogoColor} setHoveringLogoColor={setHoveringLogoColor} />
        </div> */}
      </div>

      <div style={{ borderBottomStyle: "solid", borderColor: "black", borderBottomWidth: "1px", width: "50vw", margin: "2vh 0" }}></div>
      <div style={{ textAlign: "center", fontSize: "3vh", fontFamily: "cinzel", width: "50vw" }}><i>"Inspire to pick up <u>BOOKS</u>, not <u>SCREENS</u>."</i></div>
      <div style={{ borderBottomStyle: "solid", borderColor: "black", borderBottomWidth: "1px", width: "50vw", margin: "2vh 0" }}></div>
      <div style={{ display: "flex" }}>
        <ProductCard image={SingleStraight} image2={SingleSide} containers={"1"} bookmarks={"1,500"} chosenLogoColor={chosenLogoColor}
          chosenRibbonColor={chosenRibbonColor} logoText={textOrImage === TEXT ? logoText : ''} logo={textOrImage === IMAGE ? (logo ? logo : '') : ''}
          description={"5 quotes x 300"} />
        <ProductCard image={DoubleStraight} image2={DoubleSide} containers={"2"} bookmarks={"3,000"} chosenLogoColor={chosenLogoColor}
          chosenRibbonColor={chosenRibbonColor} logoText={textOrImage === TEXT ? logoText : ''} logo={textOrImage === IMAGE ? (logo ? logo : '') : ''}
          description={"5 quotes x 600"} />
        <ProductCard image={QuadStraight} image2={QuadSide} containers={"4"} bookmarks={"6,000"} chosenLogoColor={chosenLogoColor}
          chosenRibbonColor={chosenRibbonColor} logoText={textOrImage === TEXT ? logoText : ''} logo={textOrImage === IMAGE ? (logo ? logo : '') : ''}
          description={"5 quotes x 1,200"} />
      </div>

      {/* <FinalBookmark color={+hoveringRibbonColor.id !== -1 ? hoveringRibbonColor.hex : chosenRibbonColor.hex} logo={logo}
        fill={hoveringLogoColor !== NEITHER ? hoveringLogoColor : chosenLogoColor} logoWidth={logoWidth} logoHeight={logoHeight} /> */}

      {/* <BookmarkDigital ribbonColor={chosenRibbonColor} logoColor={chosenLogoColor} logoImage={logo} logoText={logoText} textOrImage={textOrImage} /> */}

      {/* <div style={{ borderBottomStyle: "solid", borderColor: "black", borderBottomWidth: "1px", width: "50vw", margin: "2vh 0" }}></div> */}
      {/* <div style={{ borderBottomStyle: "solid", borderColor: "black", borderBottomWidth: "1px", width: "50vw", margin: "2vh 0" }}></div> */}
      {/* <div style={{ textAlign: "center", marginBottom: "2vh" }}>Also, if you have any issues making your logo have a transparent background, just contact us below :)</div> */}

      <div style={{ borderBottomStyle: "solid", borderColor: "black", borderBottomWidth: "1px", width: "50vw", margin: "2vh 0" }}></div>
      <div style={{ textAlign: "center", fontSize: "3vh", fontFamily: "Georgia", width: "50vw" }}>Email us if you are interested!</div>
      <div style={{ borderBottomStyle: "solid", borderColor: "black", borderBottomWidth: "1px", width: "50vw", margin: "2vh 0" }}></div>

      <div style={{ color: "white", backgroundColor: "#202A44", width: "100%", padding: "2vh 0", textAlign: "center" }}>
        <div>contact@ribbonbookmarks.com</div>
        <div>Copyright {new Date().getFullYear()}</div>
        {/* <div>(Patent-pending product)</div> */}
        <div style={{ color: "gray" }}>
          <a target="_blank" rel="noopener noreferrer" href="/terms" style={{ color: "gray", textDecoration: "none" }}>Terms of Service</a> | <a target="_blank" rel="noopener noreferrer" href="/privacy" style={{ color: "gray", textDecoration: "none" }}>Privacy Policy</a>
        </div>
      </div>
    </div>
  );
}

export default BookmarksPage;